<template>
  <div>
    <div>
      <h1 class="font-bold text-2xl mb-4">
        {{ $t('Twitter Setting') }}
      </h1>
      <div class="collapse collapse-wrapper my-8">
        <input type="checkbox" v-model="isTwitterOpen" />
        <div class="collapse-title">
          <div class="f-btw">
            <div class="text-xl font-bold">
              {{ $t('Accounts') }}
            </div>
            <div class="f-str space-x-2">
              <d-btn class="" size="xs" color="info" @click.stop="addFollow">
                {{ $t('Add') }}
              </d-btn>
              <d-btn
                class=""
                size="xs"
                color="primary"
                @click.stop="saveFollow"
              >
                {{ $t('Save') }}
              </d-btn>
            </div>
          </div>
        </div>
        <div class="collapse-content">
          <div
            class="collapse-content-inner p-4 space-y-4 divide-y divide-gray-100"
          >
            <div v-for="(follow, index) of follows" :key="index">
              <div :class="['f-str space-x-4', index > 0 ? 'mt-2' : '']">
                <div class="f-btw">
                  <span class="font-bold mr-2">Username:</span>
                  <input
                    v-model="follow.username"
                    class="text-center input input-bordered w-32 input-sm border-solid"
                  />
                </div>
                <div class="f-btw">
                  <span class="font-bold mr-2">Name:</span>
                  <input
                    disabled
                    v-model="follow.name"
                    class="text-center input input-bordered w-32 input-sm border-solid"
                  />
                </div>
                <div class="f-btw flex-grow">
                  <span class="font-bold mr-2">Memo:</span>
                  <input
                    v-model="follow.memo"
                    class="text-center input input-bordered w-full input-sm border-solid"
                  />
                </div>
                <div>
                  <x-icon
                    class="btn-error btn-circle btn-xs"
                    name="x"
                    @click.stop="() => unFollow(follow)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 class="font-bold text-2xl mb-4 mt-8">
        {{ $t('Notification Setting') }}
      </h1>
      <div class="collapse collapse-wrapper">
        <input type="checkbox" v-model="isFundingRateOpen" />
        <div class="collapse-title">
          <div class="f-btw">
            <div class="text-xl font-bold">
              {{ $t('Funding Rate') }}
            </div>
            <div class="f-str space-x-2">
              <d-btn
                class=""
                size="xs"
                color="info"
                @click.stop="addFundingRateConfig"
              >
                {{ $t('Add') }}
              </d-btn>
              <d-btn
                class=""
                size="xs"
                color="primary"
                @click.stop="saveFundingRateConfig"
              >
                {{ $t('Save') }}
              </d-btn>
            </div>
          </div>
        </div>
        <div class="collapse-content">
          <div
            class="collapse-content-inner p-4 space-y-4 divide-y divide-gray-100"
          >
            <div v-for="(config, index) of fundingRate" :key="config.key">
              <div :class="['f-str space-x-4', index > 0 ? 'mt-2' : '']">
                <div class="w-32">
                  <d-select v-model="config.exchange" :items="exchanges" />
                </div>
                <div class="w-32">
                  <d-select
                    v-model="config.symbol"
                    :items="symbols[config.exchange] || []"
                  />
                </div>
                <div class="w-48 f-btw">
                  <d-select v-model="config.condition" :items="conditions" />
                </div>
                <div class="w-72 f-btw mr-4">
                  <span class="font-bold w-28 mr-2">Value (%)</span>
                  <div class="w-full">
                    <input
                      type="number"
                      class="text-center input input-bordered w-full input-sm border-solid"
                      v-model="config.value"
                      step="0.00001"
                    />
                  </div>
                </div>
                <div class="f-btw flex-grow">
                  <span class="font-bold mr-2">Memo:</span>
                  <input
                    v-model="config.memo"
                    class="text-center input input-bordered w-full input-sm border-solid"
                  />
                </div>
                <div>
                  <x-icon
                    class="btn-error btn-circle btn-xs"
                    name="x"
                    @click.stop="() => removeFundingRateConfig(config)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse collapse-wrapper mt-8">
        <input type="checkbox" v-model="isStableCoinOpen" />
        <div class="collapse-title">
          <div class="f-btw">
            <div class="text-xl font-bold">
              {{ $t('Stable Coin') }}
            </div>
            <div class="f-str space-x-2">
              <d-btn
                class=""
                size="xs"
                color="info"
                @click.stop="addStableCoinConfig"
              >
                {{ $t('Add') }}
              </d-btn>
              <d-btn
                class=""
                size="xs"
                color="primary"
                @click.stop="saveStableCoinConfig"
              >
                {{ $t('Save') }}
              </d-btn>
            </div>
          </div>
        </div>
        <div class="collapse-content">
          <div
            class="collapse-content-inner p-4 space-y-4 divide-y divide-gray-100"
          >
            <div v-for="(config, index) of stableCoin" :key="config.key">
              <div :class="['f-str space-x-4', index > 0 ? 'mt-2' : '']">
                <div class="w-32 mr-4">
                  <d-select v-model="config.coin" :items="coins" />
                </div>
                <div class="w-48 f-btw">
                  <d-select v-model="config.condition" :items="conditions" />
                </div>
                <div class="w-72 f-btw mr-4">
                  <span class="font-bold mr-2">Value</span>
                  <div class="w-full">
                    <d-number-with-separator
                      class="text-center input input-bordered w-full input-sm border-solid"
                      v-model="config.value"
                    />
                  </div>
                </div>
                <div class="f-btw flex-grow">
                  <span class="font-bold mr-2">Memo:</span>
                  <input
                    v-model="config.memo"
                    class="text-center input input-bordered w-full input-sm border-solid"
                  />
                </div>
                <div>
                  <x-icon
                    class="btn-error btn-circle btn-xs"
                    name="x"
                    @click.stop="() => removeStableCoinConfig(config)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse collapse-wrapper mt-8">
        <input type="checkbox" v-model="isLiqudationOpen" />
        <div class="collapse-title">
          <div class="f-btw">
            <div class="text-xl font-bold">
              {{ $t('Losscut') }}
            </div>
            <div class="f-str space-x-2">
              <d-btn
                class=""
                size="xs"
                color="info"
                @click.stop="addLosscutConfig"
              >
                {{ $t('Add') }}
              </d-btn>
              <d-btn
                class=""
                size="xs"
                color="primary"
                @click.stop="saveLosscutConfig"
              >
                {{ $t('Save') }}
              </d-btn>
            </div>
          </div>
        </div>
        <div class="collapse-content">
          <div
            class="collapse-content-inner p-4 space-y-4 divide-y divide-gray-100"
          >
            <div v-for="(config, index) of losscut" :key="config.key">
              <div :class="['f-str space-x-4', index > 0 ? 'mt-2' : '']">
                <div class="w-32">
                  <d-select
                    v-model="config.exchange"
                    :items="losscutExchanges"
                  />
                </div>
                <div class="w-48 f-btw">
                  <d-select
                    disabled
                    v-model="config.condition"
                    :items="conditions"
                  />
                </div>
                <div class="w-72 f-btw mr-4">
                  <span class="font-bold w-28 mr-2">Value</span>
                  <div class="w-full">
                    <d-number-with-separator
                      class="text-center input input-bordered w-full input-sm border-solid"
                      v-model="config.value"
                    />
                  </div>
                </div>
                <div class="f-btw flex-grow">
                  <span class="font-bold mr-2">Memo:</span>
                  <input
                    v-model="config.memo"
                    class="text-center input input-bordered w-full input-sm border-solid"
                  />
                </div>
                <div>
                  <x-icon
                    class="btn-error btn-circle btn-xs"
                    name="x"
                    @click.stop="() => removeLosscutConfig(config)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { EXCHANGE_OPTIONS } from '@/common/constants.js'
import XIcon from '@/components/daisy/atoms/icons/index.vue'

const _default = {
  exchange: 'bybit',
  symbol: 'BTCUSDT',
  value: 0,
  condition: 'eq',
}
export default {
  name: 'setting',

  meta: {
    title: 'Settings',
  },

  components: {
    XIcon,
  },

  data: () => ({
    configs: {},
    exchanges: [
      { value: 'bybit', label: 'BYBIT' },
      { value: 'binance', label: 'BINANCE' },
    ],
    conditions: [
      { value: 'eq', label: 'Equal' },
      { value: 'gt', label: 'Greater than' },
      { value: 'gte', label: 'Greater than or equal' },
      { value: 'lt', label: 'Less than' },
      { value: 'lte', label: 'Less than or equal' },
    ],
    coins: ['USDC', 'USDT'].map((coin) => ({
      label: coin,
      value: coin,
    })),
    losscutExchanges: [
      // { value: 'binance', label: 'BINANCE' },
      { value: 'bybit', label: 'BYBIT' },
      { value: 'bitmex', label: 'BITMEX' },
      { value: 'bitfinex', label: 'BITFINEX' },
    ],
    symbols: {
      bybit: ['BTCUSDT', 'ETHUSDT'].map((symbol) => ({
        label: symbol,
        value: symbol,
      })),
      binance: ['BTCUSDT', 'ETHUSDT'].map((symbol) => ({
        label: symbol,
        value: symbol,
      })),
    },
    follows: [],
    fundingRate: [],
    stableCoin: [],
    losscut: [],
    isFundingRateOpen: false,
    isStableCoinOpen: false,
    isLiqudationOpen: false,
    isTwitterOpen: false,
  }),

  mounted() {
    this.reload()
  },

  methods: {
    async reload() {
      this.configs = await this.$http.get('/api/v2/system/configs')
      this.follows = this.configs.twitter.follows || []
      const stableCoin = this.configs.notification.stableCoin || {}
      this.stableCoin = Object.entries(stableCoin)
        .map(([coin, conditions]) => {
          return conditions.map((condition, index) => ({
            key: `${coin}_${index}`,
            coin,
            memo: '',
            ...condition,
          }))
        })
        .flat()
      const losscut = this.configs.notification.losscut || {}
      this.losscut = Object.entries(losscut)
        .map(([key, conditions]) => {
          const [exchange] = key.split('_')
          return conditions.map((condition, index) => ({
            key: `${key}-${index}`,
            symbol: 'BTCUSDT',
            exchange,
            memo: '',
            ...condition,
          }))
        })
        .flat()
      const fundingRate = this.configs.notification.fundingRate || {}
      this.fundingRate = Object.entries(fundingRate)
        .map(([key, conditions]) => {
          const [exchange, symbol] = key.split('_')
          return conditions.map((condition, index) => ({
            key: `${key}-${index}`,
            symbol,
            exchange,
            memo: '',
            ...condition,
            value: condition.value * 100,
          }))
        })
        .flat()
      this.losscut.sort((a, b) => a.order - b.order)
      this.stableCoin.sort((a, b) => a.order - b.order)
      this.fundingRate.sort((a, b) => a.order - b.order)
    },
    addStableCoinConfig() {
      this.stableCoin.push({
        coin: 'USDC',
        condition: 'equal',
        value: 0,
        key: Date.now().toString(),
      })
    },
    removeStableCoinConfig(config) {
      this.stableCoin = this.stableCoin.filter((el) => el.key !== config.key)
    },
    saveStableCoinConfig() {
      const stableCoin = {}
      this.stableCoin.forEach((config, index) => {
        if (!config.value) {
          return
        }
        const coin = config.coin
        !stableCoin[coin] && (stableCoin[coin] = [])
        stableCoin[coin].push({
          value: config.value,
          condition: config.condition,
          memo: config.memo || '',
          order: index + 1,
        })
      })
      this.configs.notification.stableCoin = stableCoin
      this.save(this.configs)
    },
    addLosscutConfig() {
      this.losscut.push({
        symbol: 'BTCUSDT',
        exchange: 'bitmex',
        value: 0,
        condition: 'gt',
        key: Date.now().toString(),
      })
    },
    removeLosscutConfig(config) {
      this.losscut = this.losscut.filter((el) => el.key !== config.key)
    },
    saveLosscutConfig() {
      const losscut = {}
      this.losscut.forEach((config, index) => {
        if (!config.value) {
          return
        }
        const key = `${config.exchange}_${config.symbol}`
        !losscut[key] && (losscut[key] = [])
        losscut[key].push({
          value: config.value,
          condition: config.condition,
          memo: config.memo || '',
          order: index + 1,
        })
      })
      this.configs.notification.losscut = losscut
      this.save(this.configs)
    },
    addFundingRateConfig() {
      this.fundingRate.push({
        ..._default,
        key: Date.now().toString(),
      })
    },
    removeFundingRateConfig(config) {
      this.fundingRate = this.fundingRate.filter((el) => el.key !== config.key)
    },
    saveFundingRateConfig() {
      const fundingRate = {}
      this.fundingRate.forEach((config, index) => {
        if (!config.value) {
          return
        }
        const key = `${config.exchange}_${config.symbol}`
        !fundingRate[key] && (fundingRate[key] = [])
        fundingRate[key].push({
          value: config.value / 100,
          condition: config.condition,
          memo: config.memo || '',
          order: index + 1,
        })
      })
      this.configs.notification.fundingRate = fundingRate
      this.save(this.configs)
    },
    saveFollow() {
      this.configs.twitter.follows = Object.values(
        this.follows
          .filter((el) => !!el.username)
          .reduce((acc, el) => {
            if (!acc[el.username]) {
              acc[el.username] = el
            }
            return acc
          }, {})
      )
      this.save(this.configs)
    },
    unFollow(follow) {
      this.follows = this.follows.filter(
        (el) => el.username !== follow.username
      )
    },
    addFollow() {
      this.follows = [
        ...this.follows,
        {
          name: '',
          username: '',
          memo: '',
          ai: false,
          disabled: false,
        },
      ]
    },
    async save(data) {
      await this.$http.post('/api/v2/system/configs', { data })
      this.reload()
    },
  },
}
</script>
